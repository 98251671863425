export const getMroEventsByIsProcessedByDateTime = /* GraphQL */ `
  query GetMroEventsByIsProcessedByDateTime(
    $isProcessed: Int
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByIsProcessedByDateTime(
      isProcessed: $isProcessed
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        code
        odometerReadingStart
        odometerReading
        mileage
        type
        description
        dateTime
        source
        processLogs
        flagged
        isProcessed
        id
        mroDeviceSerialNumber
        username
        vehicleId
        tripId
        createdAt
      }
      nextToken
    }
  }
`;

export const listEvent = /* GraphQL */ `
    query ListEvents(
      $key: String
      $timestamp: ModelStringKeyConditionInput
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
      $sortDirection: ModelSortDirection
    ) {
      listEvents(
        key: $key
        timestamp: $timestamp
        filter: $filter
        limit: $limit
        nextToken: $nextToken
        sortDirection: $sortDirection
      ) {
        items {
          key
          timestamp
          eventId
          eventName
          diff {
            key
            old
            new
          }
          note
          username
          updatedBy
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  `;

export const listParticipantStatements = /* GraphQL */ `
  query ListParticipantStatements(
    $filter: ModelParticipantStatementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipantStatements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        month
        periodFrom
        periodTo
        filename
        mileageUnit
        fuelUnit
        rifFee
        mileage
        fuel
        trips
        fuelTaxCredit
        totalNewMileageFee
        totalNewAdjCharges
        totalNewChages
        carryoverBalance
        balance
        paidAmount
        paidAt
        note
        isProcessed
        shouldSendEmailToParticipant
        tripsSummary {
          vehicleId
          privateFuelFee
          privateFuels
          privateMileage
          privateMileageFee
          privateNet
          privateTaxDifference
          publicFuelFee
          publicFuels
          publicMileage
          publicMileageFee
          publicTaxDifference
          stateCode
        }
        adjustmentsSummary {
          vehicleId
          adjMileage
          adjMileageFee
          adjFuel
          adjFuelFee
          shortDate
        }
        vehiclesSummary {
          vehicleId
          fuel
          fuelFee
          mileage
          mileageFee
          taxDifference
        }
        createdAt
        createdBy
        # updatedAt
      }
      nextToken
    }
  }
`;

export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVehicles(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vin
        make
        model
        year
        type
        licensePlate
        registrationState
        mroType
        status
        createdAt
        epaVehicleCombinedMpg
        mro {
          id
          deviceSerialNumber
          shippedDate
          shippingCarrier
          deliveredDate
          activationDate
          firstReportedDate
          createdAt
          # updatedAt
        }
      }
      nextToken
    }
  }
`;

export const listParticipantVehiclesWithReports = /* GraphQL */ `
  query ListVehicles(
    $username: String
    $id: ModelIDKeyConditionInput
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVehicles(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        vin
        make
        model
        year
        type
        subtype
        mroType
        beginningOdometerReading
        currentOdometerReading
        mileage
        createdAt
        lastReportedVinDate
        firstDataReceivedDate
        username
        mroId
        reports {
          odometer
          tsReportDate
        }
      }
      nextToken
    }
  }
`;


export const getTripsByProcessStatusByCreatedAt = /* GraphQL */ `
  query GetTripsByProcessStatusByCreatedAt(
    $processStatus: TripProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        # recordId
        # recordTripId
        mroDeviceSerialNumber
        tsStart
        tsEnd
        # polyline
        distMro
        # odoMro
        # distGps
        # distRoute
        fuel
        # odoGps
        # odoMroStart
        # odoMroEnd
        # fuelStart
        # fuelEnd
        # fuelAdded
        # totalFuel
        username
        createdAt
        processStatus
        logs
        # processMsg
        # processedAt
        mileageFeeCents
        fuelFeeCents
        cordonFeeCents
        # todFeeCents
        # tollFeeCents
        vehicleId
        # updatedAt
        # vehicle {
        #   id
        #   vin
        #   make
        #   model
        #   year
        #   type
        #   licensePlate
        #   registrationState
        #   mroType
        #   createdAt
        #   epaVehicleCombinedMpg
        #   mro {
        #     id
        #     deviceSerialNumber
        #     shippedDate
        #     shippingCarrier
        #     deliveredDate
        #     activationDate
        #     firstReportedDate
        #     createdAt
        #     # updatedAt
        #   }
        # }
        adjustments {
          items {
            # tripId
            id
            type
            state
            stateCode
            # cordonId
            # cordonName
            # todId
            # todName
            # tollId
            adjMileage
            adjMileageFeeCents
            adjFuel
            adjFuelFeeCents
            # adjCordonFeeCents
            # todFeeCents
            # adjTollFeeCents
            note
            # content
            # createdBy
            createdAt
            # transactionId
            # paymentStatus
            # updatedAt
          }
          nextToken
        }
        tripSegments {
          items {
            mileage
            type
            stateCode
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        status
        email
        accountNo
        firstName
        lastName
        firstDataReceivedDate
        mroDevicePreference
        closedDate
        closedReason
        pilotProgram {
          shortName
        }
        flags {
          hasIntegrityViolation
          isBillingOverdue
          isBillingDefault
          isInactive
          isLegislator
          isVinMismatch
          isVIP
          isGovernmentEmployee
          isCaliforniaElected
          agreeGlobalParticipantAgreement
          agreeGlobalPrivacyPolicy
        }
        vehicles {
          items {
            id
            vin
            make
            model
            year
            type
            licensePlate
            registrationState
            reports {
              odometer
              tsReportDate
            }
            mroType
            epaVehicleCombinedMpg
            firstDataReceivedDate
            mro {
              id
              deviceSerialNumber
              shippedDate
              shippingCarrier
              deliveredDate
              activationDate
              firstReportedDate
              createdAt
              # updatedAt
            }
            createdAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getTripsWithTransactionsByProcessStatusByCreatedAt = /* GraphQL */ `
  query GetTripsByProcessStatusByCreatedAt(
    $processStatus: TripProcessStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripsByProcessStatusByCreatedAt(
      processStatus: $processStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tsStart
        tsEnd
        fuel
        fuelFeeCents
        mileageFeeCents
        mroDeviceSerialNumber
        mroId
        logs
        processStatus
        processedAt
        createdAt
      }
      nextToken
    }
  }
`;

export const getTripSegmentsByPaymentStatusByCreatedAt = /* GraphQL */ `
  query GetTripSegmentsByPaymentStatusByCreatedAt(
    $paymentStatus: TripSegmentPaymentStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripSegmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripSegmentsByPaymentStatusByCreatedAt(
      paymentStatus: $paymentStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tripId
        transactionId
        vehicleId
        username
        # createdAt
        # tsStart
        # tsEnd
        type
        state
        stateCode
        # todId
        # polylines
        # calculatedMileage
        # cordonId
        # cordonName
        # cordonEnterTime
        # cordonEnterTimeZone
        # cordonEnterFeeCents
        # tollTxId
        # tollId
        # tollEntryId
        # tollExitId
        # tollEntryTime
        # tollExitTime
        # percentage
        # percentageState
        mileage
        fuel
        # mileageFeeCents
        fuelFeeCents
        # cordonFeeCents
        # todFeeCents
        # tollFeeCents
        # tripBinIds
        paymentStatus
        # updatedAt
      }
      nextToken
    }
  }
`;

export const getTripAdjustmentsByPaymentStatusByCreatedAt = /* GraphQL */ `
  query GetTripAdjustmentsByPaymentStatusByCreatedAt(
    $paymentStatus: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTripAdjustmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTripAdjustmentsByPaymentStatusByCreatedAt(
      paymentStatus: $paymentStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tripId
        id
        username
        vehicleId
        # tripSegmentId
        # tripSegmentDate
        type
        state
        stateCode
        # cordonId
        # cordonName
        # todId
        # todName
        # tollId
        adjMileage
        adjMileageFeeCents
        adjFuel
        adjFuelFeeCents
        # adjCordonFeeCents
        # adjTodFeeCents
        # adjTollFeeCents
        note
        # content
        # createdBy
        createdAt
        transactionId
        paymentStatus
        # updatedAt
      }
      nextToken
    }
  }
`;

export const listMileageReportingOptions = /* GraphQL */ `
  query ListMileageReportingOptions(
    $username: String
    $id: ModelStringKeyConditionInput
    $filter: ModelMileageReportingOptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMileageReportingOptions(
      username: $username
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        id
        vehicleId
        deviceSerialNumber
        participant {
          accountNo
          firstName
          flags {
            isVIP
          }
          lastName
          participantPilotProgramId
          pilotProgram {
            id
            name
            shortName
          }
          status
        }
        # vehicle {
        #   vin
        #   make
        #   model
        #   year
        #   type
        #   subtype
        #   mroType
        #   beginningOdometerReading
        #   currentOdometerReading
        #   mileage
        #   createdAt
        #   lastReportedVinDate
        #   firstDataReceivedDate
        #   username
        #   mroId
        #   reports {
        #     odometer
        #     tsReportDate
        #   }

        # }
      }
      nextToken
    }
  }
`;

export const getMroEventsByMroDeviceByDateTime = /* GraphQL */ `
  query GetMroEventsByMroDeviceByDateTime(
    $mroDeviceSerialNumber: String
    $dateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMROEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMroEventsByMroDeviceByDateTime(
      mroDeviceSerialNumber: $mroDeviceSerialNumber
      dateTime: $dateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        code
        odometerReadingStart
        odometerReading
        mileage
        type
        # description
        dateTime
        # source
        id
        mroDeviceSerialNumber
        createdAt
        vehicleId
      }
      nextToken
    }
  }
`;
